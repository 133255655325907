/* Apply a nice font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  display: flex;
  justify-content: center;
  height: 100vh;
  color: #fff;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;       /* Center content horizontally */
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

h1 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  margin: 0 0 1rem;
}
ul {
  padding: 0;
  margin-bottom: 1rem;
}
li {
  list-style: none;
  padding: 0; 
  margin-bottom: .5rem;
}
.small {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  color: #7564ab
}
.room {
  color: #7564ab
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

input[type="text"] {
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 200px;
  text-align: center;
}

button[type="submit"], .button {
  font-size: 1rem;
  background-color: #ed6c24;
  color: white;
  padding: 0.5rem 1rem;
  border: 1px solid red; 
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 200px; 
}

button[type="submit"]:hover, .button:hover {
  background-color: #7564ab;
  border-color: purple
}

a, button {
  font-size: 1rem;
  background: none;
  border: none;
  color: #fad156;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
a {
border-bottom: 1px solid #fad156;
}
a:hover {
  border-bottom: 1px dashed #fad156;
}

.shake {
  animation: shake 0.5s linear;
}

video {
  margin-bottom: 1rem;
}

@keyframes shake {
  0% { transform: translate(0); }
  25% { transform: translate(-5px); }
  50% { transform: translate(5px); }
  75% { transform: translate(-5px); }
  100% { transform: translate(0); }
}

.error {
  color: #FF0000;
}
.success {
  color: #008000;
}

.debug {
  color: #808080;
  font-style: italic;
  font-size: .7rem;
  display: none;
}

/* ************
 * Header
 * ***********/
 .header {
  display: flex;             
  justify-content: center; 
  align-items: center;    
  height: 100px;          
}
.header a,
.header a:hover {
  border-bottom: none; 
}

.logo {
  max-width: 100%;      
  height: auto; 
}

/* ************
 * Footer
 * ***********/
 .footer {
  display: flex;             
  justify-content: center; 
  align-items: center;    
  height: 100px;          
}

/* ************
 * Home
 * ***********/
 .home {
  text-align: center;
 }
 .home li a {
  font-size: 1.2rem;
 }

 /* ************
 * Navlinks
 * ***********/
 .navlinks {
  padding-top: 1.5rem;
 }
/* ************
 * Help 
 * ***********/
.help-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.help-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: .5rem;
}
.help-container p {
  font-size: 1rem;
  padding: 0rem 1rem 1rem;
  border-radius: 5px;
  max-width: 100%;
  word-wrap: break-word;
  text-align: center;
}

